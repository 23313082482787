export type Club = {
    new: boolean
    name: string
    type: string
    tier: 1 | 2 | 3
    desc: string
    day: string
    freq: string
    time: string
    room: string
    prez: string
    advisor: string
    email: string
    coadvisor?: string
    coemail?: string
}

const clubs: {timestamp: string, data: {[key: string]: Club}} = {
    "timestamp": "2025-01-19T00:44:16.642Z",
    "data": {
        "13302": {
            "new": false,
            "name": "Bread Appreciation Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "The Bread Appreciation Club welcomes all bread enthusiasts to learn about the history, recipes, & culture behind bread!    A normal B.A.C. (Bread Appreciation Club) meeting starts off with a ~5-8 minute fun & informative presentation teaching the members all about bread. Whether it be a debate about white vs. wheat bread or how French & Japanese bread are different, you are guaranteed to leave with a better understanding of a prominent pillar of the world's diet! Afterward, members can participate in an activity based on what we learned, like a trivia game. Lastly, all members who completed our attendance form are entered into our daily raffle for the chance to win a prize. On special days, we may offer bread for the whole club!    Disclaimer: Unfortunately, the Bread Appreciation Club does not have the time, resources, or equipment to bake bread.    We'd loaf to see you at the club, so come by to enjoy the beauties of bread with fellow enthusiasts 🍞.",
            "day": "Tuesday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "K-14",
            "prez": "Jazmine Pieper",
            "advisor": "Nicole Menache",
            "email": "nmenache@pausd.org"
        },
        "13799": {
            "new": false,
            "name": "Neuroscience Club",
            "type": "STEM",
            "tier": 2,
            "desc": "The Gunn Neuroscience Club seeks to educate students on important topics in neuroscience and introduce them to opportunities outside of school, in the field. This will include presentations, fun videos, interactive activities like Kahoot, guest speakers, and occasional field trips to labs. Ultimately, we hope to spread an appreciation for neuroscience, and further fuel the attendees’ interest in the field!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-208",
            "prez": "Aarya Bhushan",
            "advisor": "Ms. Jacquelyn Silverbush",
            "email": "jsilverbush@pausd.org"
        },
        "13976": {
            "new": true,
            "name": "Figure Drawing",
            "type": "Visual Arts and Media",
            "tier": 1,
            "desc": "In order to create a club where students can relieve stress from their work during a short lunch break, we chose croquis among the arts. Students quickly draw a gesture drawing of a student model standing in front of them in a short time, such as 30 seconds, 1 or 5 minutes. In this process, students can draw without pressure and also learn important information about the human body in art. Student models can express emotions and refine themselves while maintaining the pose they want in a short time. After drawing, they can build a network by sharing and talking with each other.",
            "day": "Monday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "G-6",
            "prez": "Yuri Ko",
            "advisor": "Arteaga, Arlena",
            "email": "aarteaga@pausd.org"
        },
        "14387": {
            "new": false,
            "name": "Muslim Student Assosciation",
            "type": "Culture, Religion, and Language",
            "tier": 1,
            "desc": "The purpose of the Gunn MSA is to build community with other Muslim students and learn more about Islam. Our weekly meetings will create an environment where students can support each other as well as discuss important issues.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-111",
            "prez": "Zoya Raza",
            "advisor": "Julie Munger",
            "email": "jmunger@pausd.org"
        },
        "15240": {
            "new": false,
            "name": "Intramural Sports Club",
            "type": "Sports",
            "tier": 1,
            "desc": "Does lunchtime feel boring? Come play games like soccer and basketball! No experience required, just come to exercise and have fun.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "V-25",
            "prez": "Sangeet Satpathy",
            "advisor": "Mr. David Bisbee",
            "email": "dbisbee@pausd.org"
        },
        "16507": {
            "new": false,
            "name": "Team HBV",
            "type": "STEM",
            "tier": 2,
            "desc": "Team HBV is a high school chapter of the youth branch of the Asian Liver Center at Stanford University. We're dedicated to raising awareness about hepatitis B and liver cancer, especially within the Asian and Pacific Islander communities. We offer career and educational workshops, community outreach, volunteer opportunities, and cultural events to promote liver health and reduce health disparities. Whether you're interested in getting involved in the science behind liver health or passionate about community engagement, join us to make a meaningful impact in the fight against these diseases and improve our community’s well-being!",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-109",
            "prez": "Stephany Handoyo",
            "advisor": "Danielle Whichard",
            "email": "dwhichard@pausd.org"
        },
        "16843": {
            "new": true,
            "name": "Baseball Club",
            "type": "Sports",
            "tier": 1,
            "desc": "Talk about baseball and learn strategy related to the game.",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "C-7",
            "prez": "Cyrus Silva Rezvani",
            "advisor": "Max Zipperstein",
            "email": "mzipperstein@pausd.org",
            "coadvisor": "Joey Ordonez",
            "coemail": "jordonez@pausd.org"
        },
        "17040": {
            "new": true,
            "name": "Comedy Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "Comedy Club aims to provide a space for students to discuss, practice, and enjoy comedy while building an interested community of like-minded individuals to support each other's comedic endeavors.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "C-2",
            "prez": "Owen Humphreys",
            "advisor": "Warren Collier",
            "email": "wcollier@pausd.org"
        },
        "17095": {
            "new": false,
            "name": "Data Science Club",
            "type": "STEM",
            "tier": 1,
            "desc": "This club will focus on analyzing issues and designing solutions to problems from a data science perspective. Each semester will focus on either a research project with a given dataset or lessons on Statistics and Python to get familiar with common tools, terms, and concepts in the field. The main focus would be about libraries in Python, however extensions to basic machine learning concepts are also possible. Not only will we focus on real-world issues, we also aim to create and analyze conclusive data that targets local issues on campus by conducting surveys and utilizing district-provided data. Come join us on Fridays @lunch in N-206!",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-206",
            "prez": "Jolie Zhou",
            "advisor": "Gopi Tantod",
            "email": "gtantod@pausd.org"
        },
        "18279": {
            "new": true,
            "name": "Grapho-Psych Club",
            "type": "Humanities",
            "tier": 1,
            "desc": "Grapho-Psych Club is for all students who wish to learn how handwriting, or the study of graphology, can reveal a person's personality traits, struggles & successes, opinions, psychological patterns, and much more! No previous experience is needed. :)",
            "day": "Tuesday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "G-6",
            "prez": "Serena Martin",
            "advisor": "Arlena Arteaga",
            "email": "aarteaga@pausd.org"
        },
        "19025": {
            "new": false,
            "name": "Mock Trial Club",
            "type": "Discussion",
            "tier": 1,
            "desc": "Mock trial meets in N-205 after school on Mondays. We combine fields like theater, law, and debate into a fun experience for everyone! If you’re interested in acting, you can join and act as a witness; if you want a career in law, you can be an attorney; and if you just want a low-stakes and low-commitment role, you have options like clerk or bailiff. Each person gets a role depending on their interests and in the spring we compete against each other just like an actual trial.",
            "day": "Monday",
            "freq": "Once a week",
            "time": "After School",
            "room": "N-205",
            "prez": "Hyunchan An",
            "advisor": "Misha Hlasek",
            "email": "mhlasek@pausd.org"
        },
        "19333": {
            "new": false,
            "name": "Pickleball",
            "type": "Recreation",
            "tier": 1,
            "desc": "Gunn Pickleball is committed to fostering a positive and inclusive environment at Gunn High School. Our club provides students with an opportunity to relax and have fun while playing pickleball—be it through casual rallies, competitive games, or simply unwinding with the sport in a more laid-back fashion. We seek to forge bonds, and friendships—encouraging an active life to help strike a balance between academic rigors and physical activity. Whether one has been in the game for long or wants to try out things for the first time by taking part in pickleball, all are welcome to join and have fun while at it.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "Pickleball/Tennis courts",
            "prez": "Alex Misra",
            "advisor": "Mark Weisman",
            "email": "mweisman@pausd.org"
        },
        "19835": {
            "new": false,
            "name": "Economics Club",
            "type": "Humanities",
            "tier": 1,
            "desc": "Come learn about fun economic principles, such as scarcity and opportunity cost! If you’re interested in a career in economics, business, or finance, this club will be a community for you! We’ll also be playing innovative economic games as well as participating in the National Economics Challenge, a prestigious nationwide econ-bowl style competition!",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "V-5",
            "prez": "Jason Luo",
            "advisor": "Dawna Linsdell",
            "email": "dlinsdell@pausd.org"
        },
        "19916": {
            "new": false,
            "name": "Chinese Culture Club",
            "type": "Culture, Religion, and Language",
            "tier": 2,
            "desc": "Our club meets every Wednesday during lunch in H-04. We will promote and go in depth with Chinese culture to members. Some topics during our meetings include festivals, food, modern ideas, traditions in different regions, and celebrations. Some activities include presentations, online and outdoor games, and snacks.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "H-4",
            "prez": "Sabrina Han",
            "advisor": "Yanan Vrudny",
            "email": "yvrudny@pausd.org"
        },
        "20039": {
            "new": false,
            "name": "Chess Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "We play chess in N-101 every Thursday. Come to meet new people, have fun, and play chess!",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-101",
            "prez": "Ayush Agarwal",
            "advisor": "Jordan Wells",
            "email": "jwells@pausd.org"
        },
        "20245": {
            "new": false,
            "name": "412 GCC (Gunn Christian Club)",
            "type": "Culture, Religion, and Language",
            "tier": 1,
            "desc": "Vision: 412 GCC is a Gospel-Centered community where students edify one another, have fellowship, witness, and learn more about Jesus!  Mission: 412 Gunn Christian Club's purpose is to learn what it means to be a follower of Jesus and grow in an intimate relationship with Jesus, through Bible study, prayer, worship, fellowship, teaching, events, and witnessing! We'll also have a lot of fun playing games and having a great time. Everyone is welcome! See you at the club!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "F-6",
            "prez": "Jessie Han",
            "advisor": "Patricia Homles",
            "email": "pholmes@pausd.org"
        },
        "20526": {
            "new": false,
            "name": "Title IX",
            "type": "Service and Wellbeing",
            "tier": 2,
            "desc": "We're dedicated to promoting sexual violence prevention at schools through advocacy. Throughout the year, members will be collaborating with local administrators and other community members on educational content, policy-writing, bringing guest speakers to campus, social media projects, and more! Title IX Club is open to everyone, whether you're a survivor, someone who is passionate about sexual violence prevention, or just interested in advocacy.",
            "day": "Tuesday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "N-110",
            "prez": "Zoe Mukamal",
            "advisor": "Kate Weymouth",
            "email": "kweymouth@pausd.org"
        },
        "20732": {
            "new": false,
            "name": "Ceramics Club",
            "type": "Visual Arts and Media",
            "tier": 1,
            "desc": "Ceramics club is an open space for students who love to create. We will lead clay projects that club members will make and get to keep. No experience needed. Everyone, no matter the skill level, will find that there is a project suited to them, along with kind club officers who love to help.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "M-3",
            "prez": "Mia Kimura",
            "advisor": "Victoria Buck",
            "email": "vbuck@pausd.org"
        },
        "21226": {
            "new": false,
            "name": "Thespian Club",
            "type": "Music and Performing Arts",
            "tier": 3,
            "desc": "A place where we play silly theatre games and a great outlet to get involved in Gunn's theatre community",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "Little Theatre",
            "prez": "Arturo Garrido Gomez",
            "advisor": "Kristen Lo",
            "email": "klo@pausd.org"
        },
        "22965": {
            "new": false,
            "name": "Japan Culture Club",
            "type": "Culture, Religion, and Language",
            "tier": 2,
            "desc": "The purpose of the Japanese Culture Club is to educate students about the many aspects of Japanese Culture. We want to emphasize traditions, history, and pop culture. We want to teach the rich culture of this amazing country to all students!  We will have a wide variety of lessons taught by the members of our club, including Japanese pop culture, traditions, and history. We will also have hands-on activities, such as calligraphy and food days. We will also include different pop-culture lessons in the format of videos and presentations, including occasional Japanese movie viewing (Anime, Documentary, etc) that will expose members to the present-day trends and culture in Japan.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "H-3",
            "prez": "Sarah Xie",
            "advisor": "Matt Hall",
            "email": "mhall@pausd.org"
        },
        "23897": {
            "new": false,
            "name": "Autonomous Robotics Club",
            "type": "STEM",
            "tier": 1,
            "desc": "In autonomous robots club, you will learn all about how the software behind autonomous vehicles allows them to see, think, and take actions. Some topics we cover include computer vision, trajectory planning, and 3D mapping. We plan to build our own mazerunner robot this year!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-102",
            "prez": "Stephen Zhang",
            "advisor": "Kristen Owen",
            "email": "kowen@pausd.org"
        },
        "24844": {
            "new": false,
            "name": "AI and Policy Club",
            "type": "Discussion",
            "tier": 1,
            "desc": "AI and Policy aims to build a tight-knit community for students to delve into policy analysis and creation focused on AI. Through engaging activities like lively mock debates and moot courts, comprehensive policy research projects, current event discussions, and guest speaker sessions. Participants actively explore the art and mechanics of policymaking, with a strong emphasis on AI and technology governance, fostering analytical thinking and informed discourse. This club serves as a dynamic hub for young minds to dissect, comprehend, and deliberate upon the intricacies of policies, enriching their grasp of real-world decision-making processes.",
            "day": "Tuesday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "N-211",
            "prez": "Ruddhra Gupta",
            "advisor": "Khoa Dao",
            "email": "kdao@pausd.org"
        },
        "25177": {
            "new": false,
            "name": "Climbing Club",
            "type": "Discussion",
            "tier": 1,
            "desc": "Climbing Club will be discussing climbing technique, learning climbing trivia, competing in strength challenges, talking about training, and more, every week at lunch. All skill levels are invited; if you've never climbed before, that's no problem!",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "M-4",
            "prez": "Cameron Ennis",
            "advisor": "Anita Su",
            "email": "asu@pausd.org"
        },
        "25195": {
            "new": true,
            "name": "Poetry Club",
            "type": "Humanities",
            "tier": 1,
            "desc": "In absurdity’s lair we share  Pieces that move and ensnare–  Our own or well-known,  At the end we’ll have grown,  And don’t worry, no need to prepare!    We play games, learn about new styles  We might even feed crocodiles!  Open mics and inside lore,  A world to explore,  As writers we’ll all travel miles    If you read or you write or you might  Come and try it, we won’t bite  If this sounds like you,  And our sillies ring true,  Then Poetry Club might be write!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-107",
            "prez": "Mars Ezakadan",
            "advisor": "Ethan Halter",
            "email": "ehalter@pausd.org"
        },
        "25549": {
            "new": false,
            "name": "United Computations",
            "type": "STEM",
            "tier": 2,
            "desc": "United Computations brings together a community of people interested in CS. During our weekly lunch meetings, we explore the various fields of CS through hands-on activities, guest speakers, lectures, and more. We also plan and run Gunn’s annual hackathon (GunnHacks 10.0) -- and any club member can apply to help out with publicity, website, events planning, and sponsorships! This year, we also plan to launch projects to help groups in our community revamp their websites and host additional introductory coding lessons to anyone interested. We’re one of the longest-running AND largest CS clubs at Gunn. Anyone is welcome to join!",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-215",
            "prez": "Stella Su",
            "advisor": "Josh Paley",
            "email": "jpaley@pausd.org"
        },
        "27119": {
            "new": true,
            "name": "Pokémon Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "Pokémon Club is a club where we talk about more technical mechanics of Pokémon beyond what players find during casual play. We mainly focus on Pokémon battles in Pokémon GO, Pokémon Scarlet and Violet, and Pokémon Sword and Shield. Battles are done using Pokémon Showdown. We will also discuss nuzlockes, shiny-hunting, RNG manipulation, and Pikachu.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-108",
            "prez": "Kwan Ming Mang",
            "advisor": "Terence Kitada",
            "email": "tkitada@pausd.org"
        },
        "27173": {
            "new": false,
            "name": "Dream Volunteers",
            "type": "Service and Wellbeing",
            "tier": 1,
            "desc": "Volunteering in the community as well as in Costa Rica, Guatemala, Vietnam, and India! Earn volunteer hours!",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "G-4",
            "prez": "Anaya Lakhani",
            "advisor": "Gabriela Garger",
            "email": "ggarger@pausd.org"
        },
        "29691": {
            "new": false,
            "name": "Cubing Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "Whether you are determined to be a cuber for life or just a beginner, Cubing Club is open to you! We are happy to have cubers of all skill levels, and even if you don't know how to solve a cube yet, come and watch others or ask someone to teach you! Cubing is underrated, and we hope to spread this hobby! Join today to become a cuber!",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "K-10",
            "prez": "Matt Mao",
            "advisor": "Clare Gleeson",
            "email": "cgleeson@pausd.org"
        },
        "29801": {
            "new": false,
            "name": "Pandora's Box Creative Magazine",
            "type": "Visual Arts and Media",
            "tier": 2,
            "desc": "Pandora’s Box is a student-led creative magazine that showcases writing, art, and photography from Gunn students. Pandora’s Box has been in production for over 20 years. Come join us and be a part of the team!",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-114",
            "prez": "Kaylee Cheng",
            "advisor": "Paul Dunlap",
            "email": "pdunlap@pausd.org"
        },
        "29922": {
            "new": false,
            "name": "Model United Nations",
            "type": "Competition",
            "tier": 3,
            "desc": "Model UN is the club for all students who want to become more confident speakers, gain the skills to think on a global scale, and learn something new. The club runs speaking games, gives presentations on a variety of interesting topics, and travels to conferences around the nation. We also host our own conference for new delegates. No experience required!",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "K-8",
            "prez": "Yoonseo Lee",
            "advisor": "Jeff Patrick",
            "email": "jpatrick@pausd.org"
        },
        "29942": {
            "new": false,
            "name": "Psychology Club",
            "type": "Humanities",
            "tier": 1,
            "desc": "Gunn Psych club is a student-led community dedicated to learning about the human mind and behavior.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "C-2",
            "prez": "Kate Hamilton",
            "advisor": "Warren Collier",
            "email": "wcollier@pausd.org"
        },
        "30319": {
            "new": true,
            "name": "Online Party Games Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "Online Party Games Club is a fun area where students can relax and play fun online multiplayer party games together! Some examples of games we play include Gartic Phone, skribbl.io, Jackbox Party Pack, and more! We also offer small treats for all participating members. Our club differs from other gaming clubs in that our club focuses on online party games, similar to educational tools like Kahoot and Gimkit. These games often lack a max amount of players and don’t require members to have anything more than a browser to play on.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "J-10",
            "prez": "Ryan Tse",
            "advisor": "Ning Xu",
            "email": "nxu@pausd.org"
        },
        "35985": {
            "new": false,
            "name": "Best Buddies",
            "type": "Social",
            "tier": 1,
            "desc": "The Best Buddies Club is an inclusive community that fosters connections and friendships between FUTURES/SpEd students and their compassionate peers. This unique club creates a safe and welcoming space where understanding, acceptance, and genuine friendships flourish. The club's primary mission is to promote empathy, awareness, and inclusivity within the school community. By bridging the gap between students with special needs and their peers, Best Buddies aims to break down stereotypes, reduce stigmas, and create a supportive environment where everyone feels valued and understood.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "K-12",
            "prez": "Vin Bhat",
            "advisor": "Shawna Lum",
            "email": "slum@pausd.org",
            "coadvisor": "Natalia Hawthorne",
            "coemail": "nhawthorne@pausd.org"
        },
        "38776": {
            "new": false,
            "name": "History Club",
            "type": "Humanities",
            "tier": 1,
            "desc": "The Gunn History Club recognizes the importance of understanding the past to shape a better future. Our club is dedicated to stimulating historical interests, exploring our cultures and heritage, connecting with other historians, enriching our understanding of the present, and inspiring meaningful action for the future. The purpose of this club shall be to: Foster an interest in history among high school students, Promote cultural understanding and diversity,  Conduct and provide a safe space for the exchange of ideas and historical research, Explore the stories, people, and events that have shaped our world, Inspire meaningful action for the future based on lessons learned from the past, Support civic and historical organizations in our area, and Meet other historians.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "C-1",
            "prez": "Abhivir Iyer",
            "advisor": "Chris Johnson",
            "email": "cjohnson@pausd.org"
        },
        "38918": {
            "new": false,
            "name": "Gender Sexuality Alliance (GSA)",
            "type": "Discussion",
            "tier": 2,
            "desc": "A safe space for LGBTQ+ students and a place for advocacy for our community at Gunn and beyond, focused on student presentations, discussions, and conversation.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "H-2",
            "prez": "Noah Murase",
            "advisor": "Claudia Schroeppel",
            "email": "cschroeppel@pausd.org"
        },
        "39391": {
            "new": false,
            "name": "Organic Garden Club",
            "type": "Recreation",
            "tier": 2,
            "desc": "We grow and harvest food for student and classroom curriculum. All are welcome, no experience necessary!",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "The Garden (by K-5)",
            "prez": "Lauren Lee",
            "advisor": "Cindy Peters",
            "email": "cpeters@pausd.org"
        },
        "39614": {
            "new": false,
            "name": "Women in STEM",
            "type": "STEM",
            "tier": 1,
            "desc": "The purpose of Gunn Women in STEM club is to make female students feel welcomed and empowered in the STEM community. We will have various speakers come in, have virtual speaker presentations, and play lots of fun games like Kahoots and Quizlets. We will also have food at every meeting!",
            "day": "Thursday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "J-2",
            "prez": "Sonali Dhir",
            "advisor": "Jena Lee",
            "email": "jlee@pausd.org"
        },
        "40412": {
            "new": false,
            "name": "Business Club",
            "type": "Humanities",
            "tier": 2,
            "desc": "The Gunn Business Club focuses on applying business knowledge to the real world, developing public speaking skills, refining leadership abilities, and bringing people together through four fields of business: management, marketing, hospitality, and finance. Members will also have the opportunity to engage with guest speakers, engage in national business competitions, business trivia, and team bonding! Our main mission is to help everyone better prepare for their future and emerge to become experienced and professional leaders. Everyone is welcome to join!",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-202",
            "prez": "Melinda Huang",
            "advisor": "Emily Yun",
            "email": "eyun@pausd.org"
        },
        "41078": {
            "new": false,
            "name": "Vietnamese Culture Club (VCC)",
            "type": "Culture, Religion, and Language",
            "tier": 3,
            "desc": "The Vietnamese Culture Club (VCC) is dedicated to the exploration, education, and understanding of the Vietnamese customs, traditions, and language. Through music, food, and art, our goal is to have every member develop a greater appreciation for Vietnamese culture. We welcome all students interested to partake in our wide range of inclusive social activities and cultural celebrations.",
            "day": "Thursday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "N-103",
            "prez": "Sylvie Nguyen",
            "advisor": "Timothy Young",
            "email": "tyoung@pausd.org",
            "coadvisor": "Anita Su",
            "coemail": "asu@pausd.org"
        },
        "43135": {
            "new": false,
            "name": "Science Bowl",
            "type": "STEM",
            "tier": 2,
            "desc": "We are Gunn Science Bowl, a school club dedicated to spreading curiosity for STEM and catalyzing exploration. Annually, we compete in the National Science Bowl, a nationwide competition hosted by the U.S. Department of Energy. We seek to create a community of intelligent, driven individuals in the pursuit of knowledge, and while we strive for excellence, we also promote an environment of teamwork, ingenuity, and creativity.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "In the J-bldg. Will finalize based on where advisor wishes to meet & availability.",
            "prez": "Justin Oh",
            "advisor": "Heather Mellows",
            "email": "hmellows@pausd.org"
        },
        "43418": {
            "new": false,
            "name": "Physics Club",
            "type": "STEM",
            "tier": 2,
            "desc": "The Gunn Physics Club will have interactive presentations and demonstrations on content both in and beyond physics classes. We provide members the opportunity to review, attempt more challenging questions, study for AP exams, prepare for physics competitions (such as the F=ma & USAPhO), and learn about other interesting areas of physics. All are welcome; no prior experience required!",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "J-9",
            "prez": "Josh Shin",
            "advisor": "Shilpan Sheth",
            "email": "ssheth@pausd.org"
        },
        "43441": {
            "new": false,
            "name": "National Art Honor Society",
            "type": "Visual Arts and Media",
            "tier": 2,
            "desc": "Join National Art Honor Society in M-4 to learn about all things visual art, creativity, and art appreciation! Participate in fun art projects, hands-on activities, art games and challenges, and become a member of the National Art Honor Society. No previous experience necessary, just creativity and an interest in art! Snacks/candy provided to fuel your creative visions!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "M-4",
            "prez": "Saira Gupta",
            "advisor": "Anita Su",
            "email": "asu@pausd.org"
        },
        "44166": {
            "new": false,
            "name": "TEDxGunnHighSchool",
            "type": "Social",
            "tier": 2,
            "desc": "Our mission is to cultivate ideas and conversation through an annually hosted TEDx conference held in Spangenberg Theatre. Our three committees, business, speakers, and design work hard each year to improve quality of speakers and quality of experience for all our audience members. The speakers committee works with each speaker individually to write, edit and rehearse each talk. The business committee raises all funds to cover the cost of a fully fledged TEDx conference, which include grants and small fundraisers. The design committee creates logos, merchandise design, and social media designs.  It's TEDx, but Gunn.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "C-8",
            "prez": "Violet Tivol",
            "advisor": "Daljeet Gill",
            "email": "dgill@pausd.org"
        },
        "44581": {
            "new": false,
            "name": "Improv Club",
            "type": "Music and Performing Arts",
            "tier": 2,
            "desc": "Improv performances, lunch meetings, and a regular opportunity to perform! Improv club provides lovers of improv---a theatrical/comedic discipline featuring performances made up on the spot---the opportunity to hone their craft, build a community of friends, create great performances together, and have fun!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "Little Theatre, S-1",
            "prez": "Lionel Humphreys",
            "advisor": "Kristen Lo",
            "email": "klo@pausd.org"
        },
        "44929": {
            "new": false,
            "name": "Civic Engagement Education Project",
            "type": "Service and Wellbeing",
            "tier": 1,
            "desc": "The Civic Engagement and Education Project is a student-led club at Gunn focused on increasing voter education in high schoolers. It was created with the League of Women Voters Palo Alto, a nonpartisan, nonprofit organization (for all genders, not just women) dedicated to encouraging civic engagement in the government (voting).    The goal of the club is to create greater awareness surrounding voting habits and registration details at Gunn. CEEP will do this through:  - Mock elections with sample ballots  - Registration/education drives  - Social media posts  - Holding meetings every other week",
            "day": "Monday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "C-8",
            "prez": "Namya Kasturi",
            "advisor": "Tara Firenzi",
            "email": "tfirenzi@pausd.org"
        },
        "45234": {
            "new": false,
            "name": "Key Club",
            "type": "Service and Wellbeing",
            "tier": 2,
            "desc": "Key Club is a service-forward club that exists at many schools all over the nation. At each meeting, we complete a service activity (you can earn volunteer hours!). We also plan and discuss outside service opportunities. We are connected with other Key Clubs at schools in the area, and there are monthly connection events with them.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-110",
            "prez": "Tess Bogart",
            "advisor": "Kate Weymouth",
            "email": "kweymouth@pausd.org"
        },
        "45315": {
            "new": false,
            "name": "Graphic Design Club",
            "type": "Visual Arts and Media",
            "tier": 1,
            "desc": "Anyone with any level of experience with graphic design who are interested in it is welcome. There is no prior experience needed. Together, we will go over basic graphic design tools -- photoshop and illustrator. Then, we will work on different graphic design projects to improve our understanding and skills for graphic design.",
            "day": "Wednesday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "P-117",
            "prez": "Chaewon Lee",
            "advisor": "Mark Gleason",
            "email": "mgleason@pausd.org"
        },
        "46128": {
            "new": true,
            "name": "Architecture Club",
            "type": "Visual Arts and Media",
            "tier": 1,
            "desc": "This club welcomes anybody interested in architecture, art, or anyone who enjoys building/arts and crafts! Our goal is to introduce a new subject to Gunn, and teach members about the history of architecture, as well as doing mini hands-on group projects to reinforce what we've learned about building techniques and styles. We will play mini games for prizes and compete in building competitions like creating our own haunted houses and gingerbread houses. All are welcome!",
            "day": "Thursday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "J-7",
            "prez": "Pernille Macskassy",
            "advisor": "Elena Zizmor",
            "email": "ezizmor@pausd.org"
        },
        "46354": {
            "new": true,
            "name": "Celebrating Female Composers",
            "type": "Music and Performing Arts",
            "tier": 1,
            "desc": "Celebrating Female Composers is a club for everyone!  We will not be covering difficult chord theory, so being able to play an instrument is not a prerequisite.     In our meetings we will cover the biographies of female composers from all time periods. This includes- Classical/baroque, romantic, contemporary, jazz, rock/metal, pop and more!   Our presentations will include a club playlist that will contain music from all composers we have covered.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-205",
            "prez": "Alexandra Zhupanov",
            "advisor": "Misha Hlasek",
            "email": "mhlasek@pausd.org"
        },
        "47407": {
            "new": false,
            "name": "HOSA",
            "type": "STEM",
            "tier": 3,
            "desc": "HOSA stands for Health Occupations Students of America. It is a national organization that promotes career opportunities in the healthcare industry. HOSA helps students develop leadership and technical skill competencies through a variety of regional and national conferences and competitions. We plan on having several guest speakers, attending HOSA competitions, and we will overall develop people's leadership skills in the medical field by presenting about several topics relating to the medical field they may be interested in.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "L-2",
            "prez": "Aareev Panda",
            "advisor": "Angela Merchant",
            "email": "amerchant@pausd.org"
        },
        "47526": {
            "new": true,
            "name": "Newcomer Buddy Club",
            "type": "Service and Wellbeing",
            "tier": 1,
            "desc": "Are you a newcomer at Gunn or a buddy affiliated with the Newcomer Buddy Program? Whether you've joined mid-year from within the state, out of state, another country, or if you're a buddy supporting these students, the Buddy Club is here for you! Our mission is to provide a structured and welcoming environment for both newcomers and their buddies. We facilitate regular check-ins and meetings, helping everyone build connections, navigate the school environment, and foster a strong sense of belonging. Through our efforts, we aim to create an inclusive and supportive community for everyone at Gunn.",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "F-1",
            "prez": "Zhenxi Li (Phoebe)",
            "advisor": "Philip Lyons",
            "email": "plyons@pausd.org"
        },
        "48302": {
            "new": false,
            "name": "Sports Fan Club",
            "type": "Sports",
            "tier": 1,
            "desc": "The purpose of the Sports Fan Club is to provide a fun and welcoming environment for anyone to have an engaging discussion/debate about sports related topics. Anyone is welcome, prior sports knowledge is not required! We want to educate and inform people about recent sports news and headlines. We will also do fantasy leagues for basketball, football, and others. We hope to grow this community and spread sport knowledge, while having fun!",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-113",
            "prez": "Andre Lee",
            "advisor": "Katherine Ja",
            "email": "kja@pausd.org"
        },
        "48322": {
            "new": false,
            "name": "Board Game Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "Our board gaming club is a group of people who regularly meet to play board games during lunch times. All members will chat with each others and having fun to enjoy the board game.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "PE Classroom",
            "prez": "Agastya Goel",
            "advisor": "Steve Ames",
            "email": "sames@pausd.org"
        },
        "48650": {
            "new": false,
            "name": "Korean Club",
            "type": "Culture, Religion, and Language",
            "tier": 1,
            "desc": "This club is for students who love Korean culture to share their interest and and spread the beauty of Korean culture to all gunn high school students. During the club, we will be watching several Korean media and talk with each other to share our thoughts. Also, over the course of the semester, we will be sharing informations for competitions or other events that are from South Korean consulate or are related to Korean Organizations.",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-212",
            "prez": "Sophie Nam",
            "advisor": "Julia Choi",
            "email": "jchoi@pausd.org",
            "coadvisor": "Danning Siu",
            "coemail": "dsiu@pausd.org"
        },
        "49079": {
            "new": false,
            "name": "Joy Dance Club",
            "type": "Music and Performing Arts",
            "tier": 1,
            "desc": "Joy Dance Club - Boost Your School Life with Fun, Friends, and Positivity! Release Stress, Make Memories, and Feel the Joy!!  Balancing and navigating stress are everyday challenges in high school and life. It's not about avoiding stress, but how we manage it that counts. At the Joy Dance Club, we've found a delightful way to tackle it – dancing! A few minutes during lunch to re-energize, make memories, and let go of stress. Our club is a warm and non-judgemental community, where dance skills don't matter. All you need is a willingness to try something new and enjoy yourself. So don’t hesitate, join the Joy Dance Club today, and let the joy begin! 😊 Check out our club instagram!! https://www.instagram.com/gunn_joydance__club/",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "V-1",
            "prez": "Julia Lee",
            "advisor": "Mrs. Anderson",
            "email": "amanderson@pausd.org"
        },
        "51365": {
            "new": false,
            "name": "Aeronautics",
            "type": "STEM",
            "tier": 1,
            "desc": "At Aero, we learn aspects of engineering and discuss current topics in astronomy, aerospace, and other sciences.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "J-9",
            "prez": "David Kim",
            "advisor": "Shilpan Sheth",
            "email": "ssheth@pausd.org"
        },
        "51428": {
            "new": true,
            "name": "Film Club",
            "type": "Visual Arts and Media",
            "tier": 1,
            "desc": "Film Club’s purpose is to provide a safe and inclusive environment for Gunn students to  express their creativity through video and learn about filmmaking outside of an official class. Film Club strives to create a community for the filmmakers of Gunn to express their creativity and share their knowledge and experience with other students. The Film Club will work to achieve its goals by effectively making use of club meeting times to plan out our activities and projects. Each week, Film Club will meet to discuss a production-related topic, plan a project, or engage in another activity arranged by the club president. Film Club plans to have one large project per semester, to be determined by the club and open to all club members to help plan and execute to completion. At the end of the year, the Gunn and Paly film clubs co-host a small film festival called the PAMPA, (Palo Alto Motion Picture Awards,) where students enter their films into a friendly competition to celebrate their work.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "L-6",
            "prez": "Yusuf Baday",
            "advisor": "Edward Corpuz",
            "email": "ecorpuz@pausd.org"
        },
        "54166": {
            "new": true,
            "name": "Motorsport Club",
            "type": "Sports",
            "tier": 1,
            "desc": "The Motorsports Club is a Tier 1 club dedicated to exploring the world of racing and automotive technology. We meet every Thursday with a dozen members who share a passion for motorsports. Our discussions cover a wide range of topics, including racing lines, the intricate parts of gas and electric cars, RC cars, and the differences between Indy and F1 racing, as well as the legendary Le Mans race.  Our club is led by a team of dedicated sophomores. Katelyn Winslow serves as our President, bringing her enthusiasm for both watching and understanding the technical aspects of racing. Vanessa Dahl, who is listed as Vice President due to club charter rules, has a strong interest in motorsports and took Auto 1 as a freshman. Chloe Coponen, our Secretary, is a devoted Formula 1 fan with experience in club leadership, as currently serving as Vice President of the Gunn Tea Club. Together, they ensure our meetings are engaging and informative for all members.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "L-8",
            "prez": "Katelyn Winslow",
            "advisor": "Tomas Grim",
            "email": "tgrim@pausd.org"
        },
        "54199": {
            "new": false,
            "name": "Quiz Bowl",
            "type": "Competition",
            "tier": 2,
            "desc": "The Quiz Bowl club serves as a dynamic and intellectually stimulating platform for students to engage in competitive and collaborative academic competitions. Its primary purpose is to foster a passion for knowledge, critical thinking, and teamwork among its members. Through regular meetings and practice sessions, club participants delve into a wide array of subjects, ranging from literature and history to science and pop culture. Members develop their ability to quickly and accurately answer diverse trivia questions, honing their analytical skills and expanding their general knowledge base. The club also prepares for and participates in Quiz Bowl tournaments, where they compete against other schools regionally and nationally, putting their accumulated knowledge to the test in a fast-paced and exhilarating environment. Overall, the Quiz Bowl club offers a vibrant platform for intellectual growth, friendly competition, and the joy of learning for its enthusiastic members.",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "C-1",
            "prez": "Abhivir Iyer",
            "advisor": "Chris Johnson",
            "email": "cjohnson@pausd.org"
        },
        "54749": {
            "new": false,
            "name": "Tea Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "Tea Club is a place to relax and have a tea party in the middle of a stressful school day. We’ll experience the cultural aspects of serving and drinking tea, including tea biscuits and other tea snacks. Each month, we will focus on a different cultural tea-related theme, and potentially have optional, sophisticated discussions over tea.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "L-8",
            "prez": "Adi Sharon",
            "advisor": "Tomas Grim",
            "email": "tgrim@pausd.org"
        },
        "56585": {
            "new": false,
            "name": "Anatomy and Physiology Club",
            "type": "STEM",
            "tier": 2,
            "desc": "Meeting every week to prepare for and perform bi-weekly dissections, this club will delve into many topics related to the human anatomy as well as countless other organisms.",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "J-4",
            "prez": "Albert Yang",
            "advisor": "Micah Wynn",
            "email": "mwynn@pausd.org"
        },
        "57675": {
            "new": false,
            "name": "Red Cross",
            "type": "Service and Wellbeing",
            "tier": 1,
            "desc": "Volunteer with us to gain service hours during club meetings! We will act as an extension of the local Red Cross chapter, doing mission-related services (such as raising awareness online for disaster preparedness), organize and participate in a variety of local volunteering opportunities, and diving deeper into more information about what Red Cross is and what they do. Club perks include the opportunity to receive free CPR training and certification, access to the Red Cross network, and being officially registered as a Red Cross volunteer!",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "J-3",
            "prez": "Leilani Griffin",
            "advisor": "Mark Brassey",
            "email": "mbrassey@gmail.org"
        },
        "58908": {
            "new": false,
            "name": "DIY Crafts Club",
            "type": "Visual Arts and Media",
            "tier": 2,
            "desc": "Our purpose is to provide a space where people can have fun and be creative while creating aesthetic and trendy crafts. The projects will be simple, so members can recreate them with their own unique style, and easy, so people can create many at once. Club members will learn how to make a variety of fun crafts that they can take home with them and use for accessories, decor, gifts, etc. These include making keychains, jewelry, room decor, and painting on various things.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "H-4",
            "prez": "Valerie Yang",
            "advisor": "Yanan Vrudny",
            "email": "yvrudny@pausd.org"
        },
        "59268": {
            "new": false,
            "name": "Girls Tech Club",
            "type": "STEM",
            "tier": 2,
            "desc": "Our mission is to create a community for girls and non-binary students to explore and pursue technology, engineering, and computer science. This involves spreading opportunities to club members, group activities, guest speakers, company tours, coding, and more. Anyone interested in tech, hanging out with friends, and having fun should join!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-115",
            "prez": "Anika Kumar",
            "advisor": "Takeshi Kaneko",
            "email": "tkaneko@pausd.org",
            "coadvisor": "Savannah McDonnell",
            "coemail": "smcdonnell@pausd.org"
        },
        "59804": {
            "new": false,
            "name": "Science Olympiad",
            "type": "Competition",
            "tier": 2,
            "desc": "Science Olympiad (SciOly) promotes the study of scientific topics by preparing members for nearby competitions. Members will get to explore a variety of subjects, including biology, chemistry, physics, engineering, and environmental science, acquiring knowledge and skills applicable for future science careers. They will also partake in fun club activities, such as trivia games, hands-on experiments, and team bonding. Through studying and competing together, members will build strong connections with their peers and make new friends. No prior experience is necessary — only an interest to learn more about science!",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "L-1",
            "prez": "Samuel Chen",
            "advisor": "Laurie Pennington",
            "email": "lpennington@pausd.org"
        },
        "59885": {
            "new": false,
            "name": "Cooking Club",
            "type": "Culture, Religion, and Language",
            "tier": 2,
            "desc": "From Master Chefs to Instant Ramen slurpers, the Cooking Club is for anyone passionate about food! Whether you like the eating or the cooking part of the process, we have a place for you. Our purpose is to learn about the significance of food in our daily lives and how it shapes our cultures. We host fun activities like making pickles, build-your-own cakes, latte art lessons, and much more! Join us in N113 every Tuesday at lunch!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-113",
            "prez": "Sarah Xie",
            "advisor": "Katherine Ja",
            "email": "kja@pausd.org"
        },
        "61433": {
            "new": true,
            "name": "Research Club",
            "type": "Discussion",
            "tier": 1,
            "desc": "The club will create a close-knit community for all people interested in academic Research. Firstly, students will be introduced to new Research competitions, many of them hosted by IRO . This will allow more Gunn students to gain new achievements which promote the school. The club will teach skills that are necessary to write Research papers as High School and Undergraduate College students almost entirely on their own. It will also give an opportunity for students to learn how to write peer-reviewed Research papers, learning from a curriculum designed by a world-renowned Researcher. In addition, the ideas outlined in will foster Research as a community at Gunn and if the Research Fair is held, hopefully PAUSD and the entire Bay Area.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "C-7",
            "prez": "Neil Dixit",
            "advisor": "Max Zipperstein",
            "email": "mzipperstein@pausd.org"
        },
        "61597": {
            "new": false,
            "name": "Music Composition Club",
            "type": "Music and Performing Arts",
            "tier": 1,
            "desc": "Welcome to Music Composition Club! In this club, we will be composing many kinds of music, such as classical music, jazz, rock, and electronic music. We will be exploring both the theoretical and practical sides of music-making, and we will also create and share our own compositions. Pro or No, let's get the music going!",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "L-6",
            "prez": "Aritra Nag",
            "advisor": "Edward Corpuz",
            "email": "ecorpuz@pausd.org",
            "coadvisor": "Cindy Peteres",
            "coemail": "cpeters@pausd.org"
        },
        "62410": {
            "new": false,
            "name": "The Chariot",
            "type": "Visual Arts and Media",
            "tier": 2,
            "desc": "The Chariot is Gunn's nonpartisan, student-led social and political magazine. We aim to foster discussion of social and political events at Gunn through political forums and publishing articles. To view some of our published articles, get in touch with us, or learn more about us, visit chariotonline.wordpress.com.",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-106",
            "prez": "Isha Sinha",
            "advisor": "Justin Brown",
            "email": "jdbrown@pausd.org"
        },
        "62762": {
            "new": true,
            "name": "True Crime Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "The True Crime Club is an environment made to allow students to express their own opinions in regards to real life criminal investigations, especially those that are currently unsolved. With such a variety of evidence and testimony at hand, these cases can have a large range of opinions, and the True Crime Club wants those opinions to be heard and discussed upon.",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-107",
            "prez": "Eunho Choi",
            "advisor": "Ethan Halter",
            "email": "ehalter@pausd.org"
        },
        "62858": {
            "new": true,
            "name": "Dermatology Club",
            "type": "STEM",
            "tier": 1,
            "desc": "Gunn Dermatology club will discuss all things skin related; social media trends, tanning, cancers, and all kinds of skin conditions. We will also cover the history of dermatology and how it has advanced over time.",
            "day": "Wednesday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "Building N-101",
            "prez": "Abby Truesdale",
            "advisor": "Jordan Wells",
            "email": "jwells@pausd.org",
            "coadvisor": "Tara Firenzi",
            "coemail": "tfirenzi@pausd.org"
        },
        "64018": {
            "new": false,
            "name": "Voices of Unity Club",
            "type": "Culture, Religion, and Language",
            "tier": 1,
            "desc": "Welcome to the Voices of Unity Club, where languages that often go unheard take center stage. Join us in exploring the beauty of lesser-known languages through language spotlights, podcast discussions and documentary screenings that celebrate the diversity of our world's voices. Together, we will explore fostering unity through linguistic and cultural discovery, so every language can find its voice. Anyone is welcome to join to learn about lesser-known languages and cultures, and to share about their own language and culture.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "H-5",
            "prez": "Tanav Parth",
            "advisor": "Teresa Nino Oliva",
            "email": "tninooliva@pausd.org"
        },
        "66441": {
            "new": false,
            "name": "Reach Out, Care, Know (ROCK)",
            "type": "Service and Wellbeing",
            "tier": 2,
            "desc": "Reach Out, Care, Know (ROCK) at Gunn High School in Palo Alto, California is dedicated to reducing the stigma surrounding mental health through education, outreach, and events to remind our peers the importance of valuing one's wellness. Additionally, students participate in trainings, so they know how to be a \"ROCK\" -- someone who is able to approach a friend who may be battling a mental health issue, provide support, and connect them to the help they need and deserve. We have meetings every Thursday where we plan events such as the 5k Mental Health Awareness Walk and the mobile barnyard during Finals Week. We also participate in rock painting and Sources of Strength presentations.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-114",
            "prez": "Jennifer Li",
            "advisor": "Paul Dunlap",
            "email": "Pdunlap@pausd.org"
        },
        "68103": {
            "new": false,
            "name": "Medicine without Borders",
            "type": "STEM",
            "tier": 2,
            "desc": "In the club, Medicine without Borders, we aim to learn, inspire, and take action in the pursuit of helping underprivileged communities around the world. We will delve into the mission and activities of organizations like \"Doctors Without Borders,\" understanding the challenges faced by underprivileged communities and the impact of humanitarian efforts. Our primary objective is to discuss ways one could aid underprivileged nations in medicine and raise awareness about this topic.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "J-10",
            "prez": "Saira Gupta",
            "advisor": "Ning Xu",
            "email": "nxu@pausd.org"
        },
        "68279": {
            "new": true,
            "name": "International Cinema Club",
            "type": "Visual Arts and Media",
            "tier": 1,
            "desc": "The International Cinema Club is a community dedicated to the exploration and appreciation of global cinema. Our mission is to screen cinema from many different cultures weekly to foster further enrichment within our members. Expect to view a variety of interesting films from many differing perspectives.",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "F-6",
            "prez": "Michael Matta",
            "advisor": "Nora Matta",
            "email": "nmatta@pausd.org"
        },
        "69552": {
            "new": true,
            "name": "GeoPolitical Club",
            "type": "Discussion",
            "tier": 1,
            "desc": "The GeoPolitics Club aims to provide a detailed understanding of interactions between foreign governments, and methods of addressing these issues. However, instead of focusing on present issues and conflicts, this club will address moments in history by analyzing flaws in historical plans and pivotal international moments. Rather than engaging in debates, and profound discussion from a “modeling” perspective, this club will focus on theoretical approaches and will equip members with important international knowledge.",
            "day": "Friday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "Holmes - F-6",
            "prez": "Tilak Thiyagarajan",
            "advisor": "Patricia Holmes",
            "email": "pholmes@pausd.org"
        },
        "69923": {
            "new": false,
            "name": "Titan Journal Club",
            "type": "Visual Arts and Media",
            "tier": 1,
            "desc": "The Titan Journal is a student-run journal that gives Gunn students the opportunity to publish their research papers, articles, and essays. During club meetings, members can present and receive feedback on their works in a supportive and constructive environment.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "J-5",
            "prez": "Andrew Hwang",
            "advisor": "Laurie Pennington",
            "email": "lpennington@pausd.org"
        },
        "70249": {
            "new": true,
            "name": "Sports Data & Analytics Club",
            "type": "STEM",
            "tier": 1,
            "desc": "The purpose of the club is to explore the deeper insights behind sports through statistics and data analysis. The club will use math, analysis, analytics, as well as past trends to explore how data can impact player performance. We aim to give members a better understanding of interpreting sports analytics and its effects in sports.",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-201",
            "prez": "Winston Li",
            "advisor": "Rachel Congress",
            "email": "rcongress@pausd.org"
        },
        "71551": {
            "new": false,
            "name": "South Asian Student Association",
            "type": "Culture, Religion, and Language",
            "tier": 2,
            "desc": "Our club is a place for all people to gather and form a community, whilst learning more about the many cultures of South Asia. Everyone is welcome, and it's an incredibly fun experience! We have weekly activities, games, and events throughout the year.",
            "day": "Monday, Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "C-3",
            "prez": "Inaaya Ahmed",
            "advisor": "Daljeet Gill",
            "email": "dgill@pausd.org"
        },
        "73186": {
            "new": false,
            "name": "Helios Magazine Club",
            "type": "Visual Arts and Media",
            "tier": 2,
            "desc": "The Helios Magazine is a club open to all Gunn students who are interested in contributing to a professional and meaningful publication on campus, whether through art, design, or writing. We will work on distributing an entertaining, interesting, and thought-provoking magazine online every quarter, while teaching members the importance of ethical journalism and creative, professional writing. All welcome!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-106",
            "prez": "Sarah Grupenhoff",
            "advisor": "Justin Brown",
            "email": "jdbrown@pausd.org"
        },
        "73212": {
            "new": false,
            "name": "Math Circle",
            "type": "STEM",
            "tier": 3,
            "desc": "We seek to promote an appreciation and understanding of math beyond the typical high school curriculum by hosting weekly talks, in-house contests, games, and guest speakers. We also help students prepare for and participate in many contests throughout the year, with a focus on math competitions such as AMC, and college-run ones at Harvard, MIT and Stanford.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-214",
            "prez": "Angela Liu",
            "advisor": "Danny Hahn",
            "email": "dhahn@pausd.org"
        },
        "75375": {
            "new": true,
            "name": "Fencing Club",
            "type": "Sports",
            "tier": 1,
            "desc": "Purpose: The Gunn Fencing Club wishes to teach students the history and uniqueness of the fencing sport. We will teach them about the history and evolution of both practical fencing (medieval) and competitive fencing (modern sport). We will teach them about the three cardinal weapons in the sport: saber, epee, and foil.  Activities: During meetings, we will not involve the actual weapons in person. We will teach the students modern fencing for all three weapons spread out across the year, as it is the most accessible to learn. We will teach them both footwork and blade work. Instead of live blades, we will utilize pool noodles or foam toys.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-209",
            "prez": "Seth Li",
            "advisor": "Danning Siu",
            "email": "dsiu@pausd.org"
        },
        "75957": {
            "new": false,
            "name": "Card Game Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "Play any card game you like or learn a new game! We provide tables, cards, and chips. https://gunnwiki.org/Card_Game_Club",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "M-3",
            "prez": "Jacob Chiu",
            "advisor": "Victoria Buck",
            "email": "vbuck@pausd.org"
        },
        "76373": {
            "new": false,
            "name": "Weightlifting Club",
            "type": "Sports",
            "tier": 1,
            "desc": "Weightlifting Club is an inclusive strength community that welcomes any type of lifter - beginner to advanced. Come learn how to safely lift so you can get stronger, healthier, and achieve new PRs!",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "After School",
            "room": "Titan Gym Weight Room",
            "prez": "Jason Luo",
            "advisor": "David Bisbee",
            "email": "dbisbee@pausd.org"
        },
        "77429": {
            "new": false,
            "name": "Timewinder",
            "type": "STEM",
            "tier": 2,
            "desc": "Timewinder is a game development club that provides students with a platform to learn and experiment with Unity. From tic-tac-toe to platformers, we'll learn to develop various games and understand the key features of Unity. All skill levels are welcome, so don't be scared to stop by and see what we're up to. We hope to build a community of people who are willing to learn and grow as game developers with us!",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-109",
            "prez": "Jesse Li",
            "advisor": "Danielle Whichard",
            "email": "dwhichard@pausd.org"
        },
        "82888": {
            "new": false,
            "name": "Amnesty International Club",
            "type": "Discussion",
            "tier": 2,
            "desc": "This club focuses on Amnesty International, a private activism, and human rights group, with more than 10 million members across the world. We sign petitions, fundraise, engage and push legislation, local and national policy change, with our works reaching people like Congress, the US President, Supreme Court, etc. We also branch past the national scale and work with international governments, judicial systems, non-profits, and other private, human rights organizations. We spread awareness over current events, open discussions, and find purpose in enacting real, tangible, policy changes in the world. Simultaneously, we also wish to simplify, and foster more access to topics like human rights, big legislation, government structures, public policy, etc. and much more! Too often, these are subjects that may traditionally overlook or intimidate high school voices. Amnesty International Club strives to give Gunn High students the power, resources, and knowledge to mobilize your own action in justice. If you enjoy social justice, political science, law, current events, public policy, philosophy, environmental science, research, media, debate, etc. or simply want a voice in your own justice, Amnesty International Club is the club for you!",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "C-8",
            "prez": "Karen Tang",
            "advisor": "Tara Firenzi",
            "email": "tfirenzi@pausd.org"
        },
        "84883": {
            "new": true,
            "name": "Zine Club",
            "type": "Visual Arts and Media",
            "tier": 1,
            "desc": "Zine Club meets every Wednesday during lunch in N-109. A zine is a self-published small-circulation publication. Zines offer creative freedom: zinemakers can draw, use collage techniques, and copy-and-paste printed words about a topic of their choice, resulting in totally unique pieces of media. Members will make and explore different types of zines, as well as participate in a group zine at the end of the semester. No experience needed---we aim to create a friendly, fun, and relaxed atmosphere. Zines will be printed for sharing and distribution with the consent of the creators.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-109",
            "prez": "Mei Knutson",
            "advisor": "Danielle Whichard",
            "email": "dwhichard@pausd.org",
            "coadvisor": "Ethan Halter",
            "coemail": "ehalter@pausd.org"
        },
        "84933": {
            "new": false,
            "name": "German Club",
            "type": "Culture, Religion, and Language",
            "tier": 2,
            "desc": "We play authentic German games, listen to German music, eat and make delicious German food, and engage in activities that parallel those of a typical German teenager.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "H-2",
            "prez": "Emily Linder",
            "advisor": "Claudia Schroeppel",
            "email": "cschroeppel@pausd.org"
        },
        "87931": {
            "new": true,
            "name": "Polandball Club",
            "type": "Visual Arts and Media",
            "tier": 1,
            "desc": "Polandball club will engage in looking at polandball comics (art and comics that focuses on political cartoons), examining them, relating them to the contemporary politics they are inspired by, and make comics ourselves.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "C-3",
            "prez": "Owen Yang",
            "advisor": "Ben Beresford",
            "email": "bberesford@pausd.org"
        },
        "89570": {
            "new": false,
            "name": "Competitive Programming Club",
            "type": "Competition",
            "tier": 2,
            "desc": "In Gunn Competitive Programming Club, we teach students programming algorithms, data structures, and problem solving. We motivate and train students to participate in prestigious competitive programming contests, such as USACO, Codeforces, Google Code Jam, and other local competitions, some of which our teams have won. We will be hosting the Bay Area Programming Contest in the spring. During club time, we share knowledge and have fun with our club members!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-214 (Mr. Hahn's room)",
            "prez": "Agastya Goel",
            "advisor": "Daniel Hahn",
            "email": "dhahn@pausd.org"
        },
        "90623": {
            "new": true,
            "name": "Style Swap",
            "type": "Recreation",
            "tier": 1,
            "desc": "We would meet, swap clothes, keep track of them, and return them.",
            "day": "Wednesday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "N-207",
            "prez": "Vaani Saxena",
            "advisor": "Florina Limburg",
            "email": "flimburg@pausd.org"
        },
        "91240": {
            "new": false,
            "name": "French Culture Club",
            "type": "Culture, Religion, and Language",
            "tier": 2,
            "desc": "Join us in room H-1 on Thu to learn more about French culture. We will have fun events, games, challenges, and plenty of food! No knowledge of French is required.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "H-1",
            "prez": "Eli Khurgin",
            "advisor": "Julia Angeles",
            "email": "jangeles@pausd.org"
        },
        "92257": {
            "new": true,
            "name": "The Breakfast Club",
            "type": "Recreation",
            "tier": 1,
            "desc": "Our club’s purpose is to watch and analyze movies set in the 80’s. We will explore various genres such as science fiction, coming-of-age, and mystery. We will examine the art and technique in the motion picture through the history of 80s films and study its genre through discussion. So bring your hairspray and your walkman and let's hop onto the DeLorean and go back to the past!",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "J-8",
            "prez": "Samhita Krishnan",
            "advisor": "Ed Corpuz",
            "email": "ecorpuz@pausd.org"
        },
        "93631": {
            "new": false,
            "name": "Spanish Club",
            "type": "Culture, Religion, and Language",
            "tier": 2,
            "desc": "Join Gunn Spanish Club to explore the rich Spanish-speaking culture through games, food, and crafts! NO Spanish speaking knowledge is required! Spanish club allows you to build a close community at Gunn with people from all grades, and members of Spanish club who are sophomores-seniors have the opportunity to become part of the Spanish National Honors Society. Join us on Thursdays at lunch in room H-5.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "G-1",
            "prez": "Sophia He",
            "advisor": "Teresa Niño Oliva",
            "email": "tninooliva@pausd.org"
        },
        "93971": {
            "new": false,
            "name": "Philosophy Club",
            "type": "Discussion",
            "tier": 1,
            "desc": "Gunn Philosophy Club is a place to cultivate your critical thinking and discussion skills through examination of famous philosophers and the questions they have raised. We will be exploring the roots of philosophy, as well as questions such as, “What is free will?” and “What is justice?” This year, we will address two of the four major branches of philosophy: metaphysics (what exists?) and ethics (what is ethical?). No prior experience is necessary, and all are welcome!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-101",
            "prez": "Janus Tsen",
            "advisor": "Jordan Wells",
            "email": "jwells@pausd.org"
        },
        "94463": {
            "new": false,
            "name": "Dungeons and Dragons Club",
            "type": "Recreation",
            "tier": 2,
            "desc": "A space here long-time D&D nerds or people looking to get into the game can meet to get involved in games, learn about the game, discuss various D&D related topics, and play silly games that combine all of these aspects together.",
            "day": "Thursday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "K-15",
            "prez": "Arturo Garrido Gomez",
            "advisor": "Briana Gonzalez",
            "email": "bgonzalez@pausd.us"
        },
        "94643": {
            "new": true,
            "name": "Geography Club",
            "type": "Humanities",
            "tier": 1,
            "desc": "The Gunn Geography Club is a space where everyone with any interest level in geography is welcome! We will be able to help guide those who want to compete in geography competitions, as well as students who want to play and discuss geography games such as Geoguessr, Sporcle, Globle, and more with others.",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "V-6",
            "prez": "Devin Gupta",
            "advisor": "Arthur Kinyanjui",
            "email": "akinyanjui@pausd.org"
        },
        "94646": {
            "new": false,
            "name": "Youth Community Service - Interact (YCS-I)",
            "type": "Service and Wellbeing",
            "tier": 1,
            "desc": "Youth Community Service - Interact (YCS-I) is a community service club that works with the community organization, YCS, and the international program, Interact, to try and improve our community. Some of the events we put on are Service Day, Service Fair, Service Trip, and an Open Mic. We also regularly update club members on different community service opportunities as we are notified about them.",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-115",
            "prez": "Gibran Rahimtoola",
            "advisor": "Diane Ichikawa",
            "email": "dichikawa@pausd.org"
        },
        "94783": {
            "new": false,
            "name": "South East Asian Student Association",
            "type": "Culture, Religion, and Language",
            "tier": 2,
            "desc": "The purpose of this club is to create a community of Southeast Asian students at Gunn or anyone who is interested in Southeast Asian culture. Southeast Asian club will hold activities like Southeast Asian historical arts and crafts, fables/plays, and games. For example, Bầu cua cá cọp, lantern making, activities regarding lunar new year, etc... Furthermore, Southeast Asian food (vietnamese food, thai food, laos food, etc...) will occasionally be served and if there are any allergies or food complications, a form will be sent out a week beforehand asking about it. Other than that, if people do not want to engage in these activities they can just use the space as a place to hangout around a community of different cultures.",
            "day": "Thursday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "N-102",
            "prez": "Joanne Hong",
            "advisor": "Kristen Owen",
            "email": "kowen@pausd.org"
        },
        "95553": {
            "new": false,
            "name": "Culture Connection Club",
            "type": "Culture, Religion, and Language",
            "tier": 1,
            "desc": "The Culture Connection Club is a welcoming community to help explore and celebrate diverse cultures. Through engaging activities and discussions, we focus on a deeper understanding and appreciation of cultural differences and the similarities we share. We learn from each other and connect with new perspectives. If you are interested in cultural traditions, global issues or just meeting people from different parts of life the Culture Connection club is the place to be. Join us to bring different cultures together and create lasting connections.",
            "day": "Thursday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "G-4",
            "prez": "Yueun Hong",
            "advisor": "Gabriela Garger",
            "email": "ggarger@pausd.org"
        },
        "96576": {
            "new": false,
            "name": "PC Development Club",
            "type": "STEM",
            "tier": 1,
            "desc": "PC Development Club aims to teach students about both high-level programming concepts such as web development and the underlying low-level hardware that everything runs on. Club members will be taught how to build and setup desktop computers from scratch, as well as how to develop for today's modern web landscape.",
            "day": "Friday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "K-15",
            "prez": "Ayush Agarwal",
            "advisor": "Briana Gonzalez",
            "email": "bgonzalez@pausd.org"
        },
        "96734": {
            "new": false,
            "name": "Girl Up",
            "type": "Discussion",
            "tier": 1,
            "desc": "Girl Up is a UN based club that stands for women empowerment. It has raised over $10 million, is supported throughout the entire nation. Join to discuss controversial topics regarding women’s rights, participate in hands-on activities, and learn how YOU can make a difference! Have much-needed discussions about topics spanning from abortion to gender discrimination to household stereotypes.",
            "day": "Friday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "N-104",
            "prez": "Abby Moeljo",
            "advisor": "Marjorie Paronable",
            "email": "mparonable@pausd.org"
        },
        "97782": {
            "new": false,
            "name": "Biotechnology Club",
            "type": "STEM",
            "tier": 1,
            "desc": "Gunn Biotechnology Club welcomes all students with an interest in biotechnology. Our goal is to empower students to learn about the applications of biotechnology and the role they can play in this field. We offer weekly educational series that introduce different aspects of biotechnology and invite guest speakers from the industry to share their journeys and experiences. We hope to help our members seek an exciting career in the field of biotechnology!",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "L-2",
            "prez": "Austin Chen",
            "advisor": "Angela Merchant",
            "email": "amerchant@pausd.org"
        },
        "98344": {
            "new": false,
            "name": "Green Team",
            "type": "Service and Wellbeing",
            "tier": 2,
            "desc": "Learn about the environment and climate activism through fun activities, presentations, guest speakers, and more. We also provide many local volunteer opportunities such as tree plantings and beach cleanups!",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "J-3",
            "prez": "Riya Chaudhary",
            "advisor": "Mark Brassey",
            "email": "mbrassey@pausd.org"
        },
        "98611": {
            "new": true,
            "name": "Jewish Student Union",
            "type": "Culture, Religion, and Language",
            "tier": 1,
            "desc": "Jewish Student Union is a club affliated with the JSU organzaition. Its goal is to promote and connect students to Judism through hands-on activites.",
            "day": "Tuesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "K-1",
            "prez": "Yael Gottesman",
            "advisor": "Ariane Tuomy",
            "email": "atuomy@pausd.org"
        },
        "99480": {
            "new": false,
            "name": "Juggling Club",
            "type": "Recreation",
            "tier": 3,
            "desc": "The purpose of our club is to spread the knowledge of juggling and manipulation arts so that people can enjoy the fun it brings and hone their skills. It also gives them a healthy break from long hours of studying. We will also foster a supporting community that propagates a love for juggling.",
            "day": "Wednesday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "Senior Quad or H-3",
            "prez": "Kostya Antonov",
            "advisor": "Matt Hall",
            "email": "mhall@pausd.org"
        },
        "99785": {
            "new": false,
            "name": "ACS Chemistry Club",
            "type": "STEM",
            "tier": 2,
            "desc": "We explore different avenues within chemistry and conduct various exciting experiments!",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "J-7",
            "prez": "Albert Yang",
            "advisor": "Elana Zizmor",
            "email": "ezizmor@pausd.org"
        },
        "99986": {
            "new": false,
            "name": "Lifestyle Medicine",
            "type": "Service and Wellbeing",
            "tier": 1,
            "desc": "In this club, we will explore ways of promoting and achieving optimal health and performance within the Gunn community. Pillars include cognitive enhancement, movement/exercise, stress management, restorative sleep, social interaction, gratitude/reflection, and nutrition. Activities may include hosting guest speakers, fun activities and community events. We have an affiliation with the Stanford Lifestyle Medicine program, and members would have the opportunity to enroll in a certification program with the American College of Lifestyle Medicine.",
            "day": "Tuesday",
            "freq": "Every Other Week",
            "time": "Lunch",
            "room": "C-8",
            "prez": "Isaac Fredericson",
            "advisor": "Chris Eggert",
            "email": "ceggert@pausd.org"
        },
        "99987": {
            "new": false,
            "name": "Mixed Arts Club",
            "type": "Visual Arts and Media",
            "tier": 1,
            "desc": "From painting to creative writing to music, the Mixed Arts Club is a community built from a mixing pot of different interests that provides resources to learn about the world of art through creation. By providing snacks, music, and activities involving interdisciplinary arts, we hope to give Gunn students a break from the stress of academics and build a community with creativity.",
            "day": "Monday",
            "freq": "Once a week",
            "time": "Lunch",
            "room": "N-104",
            "prez": "Keiss Chan",
            "advisor": "Marjorie Paronable",
            "email": "mparonable@pausd.org"
        }
    }
}
export default clubs;
